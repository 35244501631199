import React, { useEffect, useState } from 'react'
import classNames from 'classnames'

// import NotFound from './NotFound'
import Room from './Room'
import Payment from './Components/Payment'

import logo from './img/logo.svg'

import styles from './app.module.scss'

const testMessagesList = [
  {
    from: 'from',
    text: 'test 1',
  },
  {
    from: 'for',
    text: 'test 2',
  },
  {
    from: 'for',
    text: 'This page shows a list of your projects by default but it can be changed to projects\' '
      + 'activity, groups, your to-do list, assigned issues, assigned merge requests, and more. You can change '
      + 'this under "Homepage content" in your preferences',
  },
  {
    from: 'for',
    text: 'This page shows a list of your projects by default but it can be changed to projects\' '
      + 'activity, groups, your to-do list, assigned issues, assigned merge requests, and more. You can change '
      + 'this under "Homepage content" in your preferences',
  },
  {
    from: 'from',
    text: 'This page shows a list of your projects by default but it can be changed to projects\' '
      + 'activity, groups, your to-do list, assigned issues, assigned merge requests, and more. You can change '
      + 'this under "Homepage content" in your preferences',
  },
  {
    from: 'for',
    text: 'This page shows a list of your projects by default it',
  },
  {
    from: 'for',
    text: 'This page shows a list of your projects by default but it can be changed to projects\' '
      + 'activity, groups, your to-do list, assigned issues, assigned merge requests, and more. You can change '
      + 'this under "Homepage content" in your preferences',
  },
  {
    from: 'from',
    text: 'This page shows a list of your projects by default but it can be changed to projects\' '
      + 'activity, groups, your to-do list, assigned issues, assigned merge requests, and more. You can change '
      + 'this under "Homepage content" in your preferences',
  },
  {
    from: 'for',
    text: 'This page a list of your projects by default but it',
  },
  {
    from: 'for',
    text: 'This page shows a list of your projects by default but it can be changed to projects\' '
      + 'activity, groups, your to-do list, assigned issues, assigned merge requests, and more. You can change '
      + 'this under "Homepage content" in your preferences',
  },
  {
    from: 'from',
    text: 'This page shows a list of your projects by default but it can be changed to projects\' '
      + 'activity, groups, your to-do list, assigned issues, assigned merge requests, and more. You can change '
      + 'this under "Homepage content" in your preferences',
  },
]

const defaultChat = {
  amount: {
    value: '1.00',
    currency: 'RUB',
  },
  created_at: '2000-01-01T00:00:00.000Z',
  description: '',
  id: '',
  number: '000000',
  public_id: '',
  status: 'pending',

}

function App() {
  // const [isNotFound, setNotFound] = useState(false)
  const [isPayment, setPayment] = useState(false)
  const [chat, setChat] = useState(defaultChat)
  const [isFullScreen, setFullScreen] = useState(false)

  const toggleFullScreen = () => {
    setFullScreen(!isFullScreen)
  }

  const getChat = () => {
    let chatId = ''

    if (window.location.pathname.length > 1) {
      // eslint-disable-next-line prefer-destructuring
      chatId = window.location.pathname.split('/')[1]
    }

    const fetchRequest = fetch(`https://c.tome.ru/api/chats/${chatId}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      // .then((res) => {
      //   if (res.status !== 200) {
      //     // setNotFound(true)
      //   }
      //   return res.json()
      // })
      // .catch((error) => {
      //   // setNotFound(true)
      //   return JSON.stringify(error)
      // })
      .then((res) => res.json())
      .catch((error) => JSON.stringify(error))

    fetchRequest.then((body) => {
      setChat(body)
      setPayment(body.status === 'success')
    })
  }

  useEffect(() => {
    getChat()
  }, [])

  // if (isNotFound) {
  //   return <NotFound />
  // }

  return (
    <div className={classNames(styles.wrap, isFullScreen && styles.wrapFullVideo)}>
      <header className={classNames(styles.header, isFullScreen && styles.disableEl)}>
        <img alt="Tome" src={logo} className={styles.logo} />
      </header>
      {isPayment
        ? (
          <Room
            isFullScreen={isFullScreen}
            toggleFullScreen={toggleFullScreen}
            chat={testMessagesList}
          />
        )
        : <Payment setPayment={() => setPayment(true)} chatId={chat.public_id} chatNumber={chat.number} />}
    </div>
  )
}

export default App
