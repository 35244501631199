import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import styles from './chat.module.scss'

const TextChat = ({ chat }) => {
  const [newMessage, setNewMessage] = useState('')
  const [messagesList, setMessagesList] = useState(chat)
  const messagesEnd = useRef()

  const scrollToEnd = () => {
    if (messagesEnd) {
      setTimeout(() => messagesEnd.current.scrollIntoView({ block: 'center', behavior: 'smooth' }), 0)
    }
  }

  useEffect(() => {
    scrollToEnd()
  }, [])

  const postMessage = () => {
    if (newMessage.length) {
      setMessagesList([
        ...messagesList,
        {
          from: 'for',
          text: newMessage,
        },
      ])
      setNewMessage('')
      scrollToEnd()
    }
  }

  const submitForm = (e) => {
    e.preventDefault()
    postMessage()
  }

  const messageItem = (message) => (
    <div
      key={`${message.from}_${message.text.replace(/\s/g, '')}`}
      className={classNames(styles.message, message.from === 'from' ? styles.messageFrom : styles.messageFor)}
    >
      <span className={styles.messageText}>{message.text}</span>
    </div>
  )

  return (
    <div className={styles.wrap}>
      <div className={styles.header}>
        <h2 className={styles.headerTitle}>Чат</h2>
      </div>
      <div className={styles.messages}>
        {/* {chat} */}
        {messagesList.map((message) => messageItem(message))}
        <div style={{ float: 'left', clear: 'both' }} ref={messagesEnd} />
      </div>
      <form onSubmit={submitForm} className={styles.footer}>
        <input
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          className={styles.input}
        />
      </form>
    </div>
  )
}

TextChat.propTypes = {
  chat: PropTypes.arrayOf(PropTypes.shape({})),
  // pushMessage: PropTypes.func,
}

export default TextChat
