import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import microphone from './img/microphone.svg'
import videoCamera from './img/videocamera.svg'
import fullScreen from './img/full-screen.svg'
import exitFullScreen from './img/exit-full-screen.svg'

import styles from './video.module.scss'

const Video = ({
  myStream,
  callerStream,
  isFullScreen,
  toggleFullScreen,
}) => {
  const [audioMuted, setAudioMuted] = useState(false)
  const [videoMuted, setVideoMuted] = useState(false)
  const myStreamRef = useRef()
  const callerStreamRef = useRef()

  useEffect(() => {
    myStreamRef.current.srcObject = myStream
    callerStreamRef.current.srcObject = callerStream
  }, [myStream])

  const toggleVideo = () => {
    setVideoMuted(!videoMuted)
    if (myStream) {
      const tracks = myStream.getVideoTracks()
      tracks[0].enabled = videoMuted
    }
  }

  const toggleAudio = () => {
    setAudioMuted(!audioMuted)
    if (myStream) {
      const tracks = myStream.getAudioTracks()
      tracks[0].enabled = audioMuted
    }
  }

  return (
    <div className={styles.wrap}>
      <video ref={myStreamRef} className={styles.myStream} autoPlay>
        <track kind="captions" />
      </video>
      <video
        autoPlay
        ref={callerStreamRef}
        className={classNames(styles.callerStream, isFullScreen && styles.callerStreamFullScreen)}
      >
        <track kind="captions" />
      </video>
      <div className={classNames(styles.controls, isFullScreen && styles.controlsFullScreen)}>
        <button className={styles.controlButton} onClick={toggleVideo}>
          <img
            alt="toggle video"
            src={videoCamera}
            className={classNames(styles.controlIco, videoMuted && styles.controlIcoDisable)}
          />
        </button>
        <button className={styles.controlButton} onClick={toggleAudio}>
          <img
            alt="toggle audio"
            src={microphone}
            className={classNames(styles.controlIco, audioMuted && styles.controlIcoDisable)}
          />
        </button>
        <button className={styles.controlButton} onClick={toggleFullScreen}>
          <img
            alt="toggle full screen"
            src={isFullScreen ? exitFullScreen : fullScreen}
            className={styles.controlIco}
          />
        </button>
      </div>
    </div>
  )
}

Video.propTypes = {
  myStream: PropTypes.shape({
    getVideoTracks: PropTypes.func,
    getAudioTracks: PropTypes.func,
  }),
  callerStream: PropTypes.shape({}),
  isFullScreen: PropTypes.bool,
  toggleFullScreen: PropTypes.func,
}

export default Video
