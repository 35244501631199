import React from 'react'
import PropTypes from 'prop-types'
import { Popover } from 'antd'

import CardWithDescription from '../CardWithDescription'

import cloud from './img/cloud.svg'
import logo from './img/logo.svg'
import video from './img/video.svg'

import styles from './styles.module.scss'

export default class Payment extends React.PureComponent {
  constructor(props) {
    super(props)
    this.componentMount = true
    this.binaryNumberArray = this.getBinaryNumberArray()
    this.interval = null
    this.intervalTime = 500

    this.state = {
      binaryNumber: this.binaryNumberArray[Math.floor(Math.random() * this.binaryNumberArray.length)],
    }

    this.setBinaryNumber()
  }

  setBinaryNumber = () => {
    this.interval = setInterval(() => {
      if (this.componentMount) {
        this.setState({
          binaryNumber: this.binaryNumberArray[Math.floor(Math.random() * this.binaryNumberArray.length)],
        })
      }
    }, this.intervalTime)
  }

  getBinaryNumber = () => `${
    Math.round(Math.random())
  }${
    Math.round(Math.random())
  }${
    Math.round(Math.random())
  }${
    Math.round(Math.random())
  }${
    Math.round(Math.random())
  }`

  getBinaryNumberArray = () => {
    const array = []
    for (let i = 0; i < 20; i += 1) {
      array.push(this.getBinaryNumber())
    }
    return array
  }

  postPayment = () => {
    const fetchRequest = fetch(`https://c.tome.ru/api/chats/${this.props.chatId}/payments`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      .then((res) => res.json())
      .catch((error) => JSON.stringify(error))

    fetchRequest.then((body) => {
      const {
        confirmation: {
          type,
          confirmation_url: confirmationUrl,
        },
      } = body
      if (type === 'redirect') {
        window.location.href = confirmationUrl
      }
    })
  }

  render() {
    const {
      chatNumber,
      setPayment,
    } = this.props
    const {
      binaryNumber,
      isContinueDisable,
    } = this.state

    return (
      <div className="base-layout">
        <div className={styles.base}>
          <CardWithDescription
            isSubmitDisable={isContinueDisable}
            submitText="Перейти к оплате"
            handleSubmitForm={setPayment}
            wrapStyles={styles.wrap}
            description={(
              <>
                Если не получилось
                <br />
                пишите, мы поможем
              </>
            )}
          >
            <div className={styles.content}>
              <div className={styles.imageWrap}>
                <img
                  alt="Регистрирация в ФНС"
                  src={cloud}
                  width={273}
                  height={168}
                  className={styles.imageCloud}
                />
                <Popover
                  visible
                  placement="top"
                  content="Чат не оплачен"
                  overlayClassName="custom-popover"
                >
                  <div className={styles.imageBankWrap}>
                    <img
                      alt="fns"
                      src={video}
                      width={31}
                      className={styles.image}
                    />
                  </div>
                </Popover>
                <div className={styles.imageLineWrap}>
                  <span className={styles.numbersText}>{isContinueDisable ? binaryNumber : '00101'}</span>
                  <div className={styles.imageLine} />
                </div>
                <div className={styles.imageLogoWrap}>
                  <img alt="tome" src={logo} />
                </div>
              </div>
              <h1 className={styles.title}>
                {`Комната №${chatNumber}`}
              </h1>
              <p className={styles.text}>
                {'Сразу после оплаты, любой у\u00A0кого есть эта ссылка сможет войти в\u00A0чат.'}
              </p>
            </div>
          </CardWithDescription>
        </div>
      </div>
    )
  }
}

Payment.propTypes = {
  chatId: PropTypes.string.isRequired,
  chatNumber: PropTypes.string.isRequired,
  setPayment: PropTypes.func.isRequired,
}
