import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Peer from 'peerjs'

import Video from '../Components/Video'
import TextChat from '../Components/TextChat'

import styles from './room.module.scss'

const Room = ({
  isFullScreen,
  toggleFullScreen,
  chat,
}) => {
  // const [yourID, setYourID] = useState('')
  const [myStream, setMyStream] = useState()
  // eslint-disable-next-line no-unused-vars
  const [callerStream, setCallerStream] = useState()
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState()

  const callAnswer = (peerCall) => {
    navigator.mediaDevices.getUserMedia({ audio: true, video: true }).then((mediaStream) => {
      peerCall.answer(mediaStream) // отвечаем на звонок и передаем свой медиапоток собеседнику
      setMyStream(mediaStream)
    }).catch((err) => { setError(err) })
  }

  // const callToNode = (peer, peerId, peerCall) => {
  //   navigator.mediaDevices.getUserMedia({ audio: true, video: true }).then((mediaStream) => {
  //     let newPeerCall = peerCall
  //     newPeerCall = peer.call(peerId, mediaStream)
  //     newPeerCall.on('stream', (stream) => { // нам ответили, получим стрим
  //       setCallerStream(stream)
  //     })
  //   }).catch((err) => { console.log(`${err.name}: ${err.message}`) })
  // }

  useEffect(() => {
    const peer = new Peer()
    // const roomId = window.location.pathname.split('/')[1]
    let peerCall

    // peer.on('open', (peerID) => {
    //   setYourID(peerID)
    // })

    peer.on('call', (call) => {
      // Answer the call, providing our mediaStream
      peerCall = call
      callAnswer(peerCall)
    })

    navigator.mediaDevices.getUserMedia({ audio: true, video: true }).then((mediaStream) => {
      // peerCall.answer(mediaStream) // отвечаем на звонок и передаем свой медиапоток собеседнику
      setMyStream(mediaStream)
    }).catch((err) => { setError(err) })
  }, [])

  return (
    <>
      <section className={classNames(styles.video, isFullScreen && styles.videoFull)}>
        {/* {video && <Video video={video} />} */}
        <Video
          callerStream={callerStream}
          myStream={myStream}
          isFullScreen={isFullScreen}
          toggleFullScreen={toggleFullScreen}
        />
      </section>
      <section className={classNames(styles.chat, isFullScreen && styles.disableEl)}>
        {/* {chat && <TextChat chat={chat} />} */}
        <TextChat
          chat={chat}
        />
      </section>
    </>
  )
}

Room.propTypes = {
  isFullScreen: PropTypes.bool.isRequired,
  toggleFullScreen: PropTypes.func.isRequired,
  // callerStream: PropTypes.shape({}),
  myStream: PropTypes.shape({}),
  chat: PropTypes.arrayOf(PropTypes.shape({})),
}

export default Room
